<template>
  <div>
    <el-aside
      class="aside"
      width="300px;"
    >
      <el-menu
        default-active="$route.path"
        exact
        class="el-menu-vertical-demo"
        :collapse="isCollapse"
        background-color="#545c64"
        text-color="#fff"
        router
      >
        <el-menu-item
          v-for="(v,i) in items"
          :key="i"
          :index="v.url"
        >
          <i class="el-icon-menu"></i>
          <span slot="title">{{v.name}}</span>
        </el-menu-item>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      role: "",
      items: [
        { name: "用户管理", url: "/userManage" },
        { name: "商品分类", url: "/classificationManage" },
        { name: "积分商品", url: "/productManage" },
        { name: "积分兑换", url: "/orderManage" },
        { name: "闲置商品", url: "/contentManage" },
        { name: "商品订单", url: "/cartManage" },
        { name: "通知管理", url: "/noticeManage" },
        { name: "论坛分类", url: "/groupManage" },
        { name: "论坛审核", url: "/forumManage" },
        { name: "轮播图管理", url: "/swipperManage" },
      ],
    };
  },
  created() {
    this.role = sessionStorage.getItem("role");
    if (this.role === "0") {
      this.items = [
        { name: "用户管理", url: "/userManage" },
        { name: "商品分类", url: "/classificationManage" },
        { name: "积分商品", url: "/productManage" },
        { name: "积分兑换", url: "/orderManage" },
        { name: "闲置商品", url: "/contentManage" },
        { name: "商品订单", url: "/cartManage" },
        { name: "通知管理", url: "/noticeManage" },
        { name: "论坛分类", url: "/groupManage" },
        { name: "论坛审核", url: "/forumManage" },
        { name: "轮播图管理", url: "/swipperManage" },
      ];
    }
  },
  mounted() {},
  methods: {},
};
</script>

<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 800px;
}
.el-aside {
  background-image: url(../../assets/opera/0eb97fc3ae1a8205f02cff8d2f06bab1.jpeg);
  color: #333;
  text-align: left;
}
</style>
