<template>
  <div id="module">
    <el-container>
      <!--左侧导航 -->
      <Nav />
      <el-container>
        <el-header style="text-align: right; font-size: 12px; ">
          <div
            style="display:flex;justify-content:space-between;"
          >
            <div style="font-size:25px;">
              校园论坛小程序
            </div>
            <div>
              <el-dropdown>
                <i
                  class="el-icon-setting"
                  style="margin-right: 15px;"
                ></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="modifyPassword">修改密码</el-dropdown-item>
                  <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <span>{{username}}&nbsp;&nbsp;{{user.level}}</span>
            </div>
          </div>
        </el-header>
        <!-- 主体部分 -->
        <el-main>
          <Breadcrumb />
          <br />
          <router-view />
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form">
        <el-form-item
          label="旧密码："
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.oldPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="新密码："
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.newPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="确认新密码："
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form.confirmPassword"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="confirmPassword"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Nav from "./Nav";
import Breadcrumb from "./Breadcrumb";
import cryptoJs from "crypto-js";
export default {
  data() {
    return {
      key: "rest@126.com",
      dialogFormVisible: false,
      username: "",
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      formLabelWidth: "120px",
      user: {},
    };
  },
  components: { Nav, Breadcrumb },
  created() {
    this.username = sessionStorage.getItem("username");
    this.user = JSON.parse(sessionStorage.getItem("user"));
  },
  methods: {
    forwardIndex() {
      this.$router.push("/operaList");
    },
    async confirmPassword() {
      let user = JSON.parse(sessionStorage.getItem("user"));
      if (this.form.oldpassword === "") {
        this.$message.error("请输入旧密码");
      }
      if (this.form.newPassword === "") {
        this.$message.error("请输入新密码");
      }
      if (this.form.confirmPassword === "") {
        this.$message.error("请输入确认新密码");
      }
      let param = {
        uid: user.uid,
        oldPassword: this.encryptDes(this.form.oldPassword.trim()),
        newPassword: this.encryptDes(this.form.newPassword.trim()),
        confirmPassword: this.encryptDes(this.form.confirmPassword.trim()),
      };
      const result = await this.$http.post("/rest/user/reset", param);
      if (result.data.returnCode !== "200") {
        this.$message.error(result.data.message);
        return;
      }
      this.dialogFormVisible = false;
    },
    modifyPassword() {
      this.dialogFormVisible = true;
    },
    //退出登录
    logout() {
      sessionStorage.removeItem("username");
      sessionStorage.removeItem("loginToken");
      this.$router.push("/login");
    },
    encryptDes(message) {
      const keyHex = cryptoJs.enc.Utf8.parse(this.key);
      const option = { mode: cryptoJs.mode.ECB, padding: cryptoJs.pad.Pkcs7 };
      const encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
      return encrypted.toString();
    },
  },
};
</script>
<style scoped>
.el-header {
  background-image: url(../../assets/opera/0eb97fc3ae1a8205f02cff8d2f06bab1.jpeg);
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
</style>